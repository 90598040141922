function onClickHeaderBurgerMenu() {
  /*toggle menu*/
  const headerToggleMenu = document.querySelector('.header-toggle');
  const headerBurger = document.querySelector('.header__burger');
  const headerToggleClose = document.querySelector('.header-toggle-close');
  const headerToggleOverlay = document.querySelector('.header-toggle .overlay');

  function toggleBurgerMenu() {
    headerToggleMenu.hidden = !headerToggleMenu.hidden;
    const {x, y} = headerBurger.getBoundingClientRect();
    headerToggleClose.style.cssText = `left:${x}px; top:${y}px;`;
    toggleBodyOverflowHidden();
  }

  headerBurger.addEventListener('click', () => toggleBurgerMenu())
  headerToggleOverlay.addEventListener('click', () => toggleBurgerMenu())
  headerToggleClose.addEventListener('click', () => toggleBurgerMenu())

  /*menu in menu*/
  const menuParentLis = document.querySelectorAll('.header-toggle-menu__parent');
  menuParentLis.forEach(li => {
    let a = li.querySelector('a');
    a.addEventListener('click', (e) => {
      e.preventDefault();
      li.classList.toggle('opened')
    })
  })
}
