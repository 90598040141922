const sliderOptionsDefault = {
  spaceBetween: 30,
  slidesPerGroupAuto: true,
  navigation: {
    nextEl: '.slider-nav__next',
    prevEl: '.slider-nav__prev',
  },
  pagination: {
    el: '.slider-nav__counts',
    type: 'custom',
    renderCustom: function (swiper, current, total) {
      return `<span class="current">${('0' + current).slice(-2)}</span>
        <span class="total">/ ${('0' + total).slice(-2)}</span>`
    }
  },
  autoplay: {
    delay: 5000,
  },
}
const addMax4SlidesOptions = {
  breakpoints: {
    768: {
      slidesPerView: 2,
      slidesPerGroup: 2
    },
    960: {
      slidesPerView: 3,
      slidesPerGroup: 3,
    },
    1600: {
      slidesPerView: 4,
      slidesPerGroup: 4,
    },
  }
}
const addMax2SlidesOptions = {
  breakpoints: {
    768: {
      slidesPerView: 2,
      slidesPerGroup: 2
    },
  }
}
const add2SlidesOnlyMobileOptions = {
  slidesPerView: 2,
  slidesPerGroup: 2
}

const sliderOptionsMax4Slides = Object.assign( {}, sliderOptionsDefault, addMax4SlidesOptions );
const sliderOptionsMax2Slides = Object.assign( {}, sliderOptionsDefault, addMax2SlidesOptions );
const sliderOptionsDiplomas = Object.assign( {}, add2SlidesOnlyMobileOptions, sliderOptionsMax4Slides );

const swiperMain = new Swiper('.max-1-slide', sliderOptionsDefault);
const swiperMax4Slides = new Swiper('.max-4-slides', sliderOptionsMax4Slides);
const swiperMax2Slides = new Swiper('.max-2-slides', sliderOptionsMax2Slides);
const sliderDiplomas = new Swiper('.diplomas-slider', sliderOptionsDiplomas);
