function onClickAccordion() {
  const accordion = document.querySelector('.steps-accordion');
  const materialAccordion = document.querySelector('.material-accordion');
  if (accordion) {
    const accordionItems = document.querySelectorAll('.steps-accordion__item');
    accordion.addEventListener('click', (e) => {
      if (!e.target.closest('.steps-accordion__item-hidden')){
        e.target.closest('.steps-accordion__item').classList.toggle('steps-accordion__item--opened');
        Array.from(accordionItems).forEach(el => {
          if (el !== e.target.closest('.steps-accordion__item')) {
            el.classList.remove('steps-accordion__item--opened');
          }
        })
      }
    })
  }
  if (materialAccordion) {
    const accordionItems = document.querySelectorAll('.material-accordion__item');
    materialAccordion.addEventListener('click', (e) => {
      if (!e.target.closest('.material-accordion__item-hidden')){
        e.target.closest('.material-accordion__item').classList.toggle('material-accordion__item--opened');
        Array.from(accordionItems).forEach(el => {
          if (el !== e.target.closest('.material-accordion__item')) {
            el.classList.remove('material-accordion__item--opened');
          }
        })
      }
    })
  }
}
