function toggleBodyOverflowHidden() {
  const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
  document.body.classList.toggle('overflow-hidden');

  if (scrollBarWidth){
    document.body.style.paddingRight = `${scrollBarWidth}px`;
    document.querySelector('.header').style.paddingRight = `${scrollBarWidth}px`;
  } else {
    document.body.style.paddingRight = '';
    document.querySelector('.header').style.paddingRight = '';
  }
}

function initPassShower() {
  const passFields = document.querySelectorAll('input[type="password"]');

  const togglePassField = (trigger, input) => {
    if(!input || input.tagName !== 'INPUT') return;
    switch (input.type) {
      case 'text':
        input.type = 'password';
        trigger.title = 'Показать пароль';
        trigger.ariaLabel = 'Показать пароль';
        trigger.classList.remove('is-show');
        break;

      case 'password':
        input.type = 'text';
        trigger.title = 'Скрыть пароль';
        trigger.ariaLabel = 'Скрыть пароль';
        trigger.classList.add('is-show');
        break;
    }
  }

  passFields.forEach(input => {
    input.outerHTML = `
    <span class="pass-shower">
      <button class="pass-shower__btn" type="button" aria-label="Показать пароль" title="Показать пароль"></button>
      ${input.outerHTML}
    </span>`;
  });

  const triggerBtns = document.querySelectorAll('.pass-shower__btn');
  triggerBtns.forEach(btn => {
    btn.addEventListener('click', (evt)=>{
      console.log(evt.currentTarget);
      evt.preventDefault();
      togglePassField(btn, btn.nextElementSibling);
    })
  })
}
